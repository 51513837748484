import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { AiOutlineCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { createRounds } from "../../store/quiz/quiz.fetch";
import { toast } from "react-toastify";
import AxiosRequest from "../../Utils/AxiosRequest";
import { quizState } from "../../store/quiz/quiz.slice";
import _, { cloneDeep } from "lodash";

let tempRounds = [];
let reduxTempRounds = [];
const QuizRoundNew = ({
  handleNext = { handleNext },
  handlePrevious = { handlePrevious },
}) => {
  const validationSchema1 = Yup.object().shape({
    name: Yup.string()
      .max(60, "You are allow to add only 60 character's long name")
      .required("Round name is required"),
    description: Yup.string()
      .max(512, "You are allow to add only 512 character's long description")
      .required("Round description is requried"),
    complexity: Yup.string().required("Selection of round complexity is requried").notOneOf(['select'], 'Selection of round complexity is requried'),
    type: Yup.string().required('Selection of round type is required').notOneOf(['select', 'ANY'], 'Selection of round type is required'),
    passing_pr: Yup.number().required("Passing percentage is required"),
    nooftry: Yup.number()
      .min(1, "minimum 1 try requried")
      .required("No.of attempt is requried"),
    noofquestion: Yup.number()
      .min(1, "minimum 1 question requried")
      .required("No. of questions is requried"),
  });

  const [initialValues, setInitialValues] = useState({
    roundId: "",
    name: "",
    description: "",
    complexity: "",
    type: "",
    passing_pr: "",
    nooftry: "",
    noofquestion: "",
  });
  const dispatch = useDispatch();
  const [round, setRound] = useState(1);
  const [quizRounds, setQuizRounds] = useState([]);
  const { quizDetail, rounds } = useSelector((state) => state.quiz);
  const [currentRound, setCurrentRound] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [roundId, setRoundId] = useState(null);
  const [reduxState, setReduxState] = useState([]);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (value, { setSubmitting, resetForm }) => {
      try {
        // console.log(value);
        setSubmitting(true);
        value.quiz = quizDetail._id;
        // 	"quiz": "64b9065ebc590b51e47dce16",
        // "title": "Single round 2",
        // "description": "This is the second single round",
        // "type": "VIS",
        // "complexity": "T",
        // "time": 60,
        // "passingMarks": 40,
        // "totalQuestions": 10

        let url, bodyData;

        if (quizRounds[currentRound - 1]) {
          url = `admin/round/${quizRounds[currentRound - 1].roundId}`;
        } else {
          url = "admin/round";
        }

        // console.log("url = ", url);
        const { data } = await AxiosRequest.post(url, {
          quiz: quizDetail._id,
          title: value.name,
          description: value.description,
          type: value.type,
          complexity: value.complexity,
          passingMarks: value.passing_pr,
          totalQuestions: value.noofquestion,
          noOfTry: value.nooftry,
        });

        // data.data.roundId = data.data._id;
        value.roundId = data.data._id;
        data.data.roundId = data.data._id;

        // console.log("data.data = ", data.data);

        if (quizRounds[currentRound - 1]) {
        //   console.log(
        //     "quizRounds[currentRound - 1] before = ",
        //     quizRounds[currentRound - 1]
        //   );
          // quizRounds[currentRound - 1] = value;
          // reduxState[currentRound - 1] = data.data;

          const _tempRound = quizRounds.map((element, index) => {
            if(element.roundId === data.data._id) {
              return value;
            } else {
              return element;
            }
          })

          setQuizRounds(_tempRound)
          tempRounds = _.cloneDeep(_tempRound);

          const _temp = reduxState.map((element, index) => {
            console.log("element.roundId = ", element.roundId, element, data.data._id, data.data);
            if(element._id === data.data._id) {
              return data.data;
            } else {
              return element;
            }
          })

          console.log("_temp = ", _temp);

          setReduxState(_temp)
          reduxTempRounds = _.cloneDeep(_temp);
        //   console.log(
        //     "quizRounds[currentRound - 1] after = ",
        //     quizRounds[currentRound - 1]
        //   );
        } else {
        //   console.log("setting quiz rounds = ", quizRounds, value);
          tempRounds = _.cloneDeep(quizRounds);
          reduxTempRounds = _.cloneDeep(reduxState);
          console.log("reduxTempRounds before= ", reduxTempRounds);
        //   console.log("temop rounds before = ", tempRounds);
          tempRounds.push(value);
          reduxTempRounds.push(data.data);
          console.log("temop rounds after = ", tempRounds);
          console.log("reduxTempRounds after= ", reduxTempRounds);
        
          setQuizRounds(tempRounds);
          setReduxState(reduxTempRounds);
        }

        // console.log("quiz rounds = ", quizRounds);

        // Create the dispatch action to create or update a single round
        // Call the dispatch api to save the single round and update the round in redux be adding _id
        // Remove the api call to create the rounds in the bulk
        setSubmitting(false);

        console.log("current round = ", currentRound, round);

        if (currentRound !== round) {
          resetForm();
          setCurrentRound(currentRound + 1);
        } else {
          dispatch(
            quizState({
              key: "rounds",
              value: reduxTempRounds,
            })
          );
        }
        toast.success(`Round ${currentRound} created successfully`);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("nooftry", 1);
  }, []);

  const handleNextButton = () => {
    // const roundReqeust = quizRounds.map((item) => {
    //   if (item.roundId) {
    //     return {
    //       roundId: item.roundId,
    //       title: item.name,
    //       description: item.description,
    //       type: item.type,
    //       complexity: item.complexity,
    //       passingMarks: item.passing_pr,
    //       noOfTry: parseInt(item.nooftry),
    //       totalQuestions: item.noofquestion,
    //     };
    //   } else {
    //     return {
    //       title: item.name,
    //       description: item.description,
    //       type: item.type,
    //       complexity: item.complexity,
    //       passingMarks: item.passing_pr,
    //       noOfTry: parseInt(item.nooftry),
    //       totalQuestions: item.noofquestion,
    //     };
    //   }
    // });
    // console.log("roundReqeust", roundReqeust);
    // dispatch(
    //   createRounds(
    //     {
    //       quizId: quizDetail._id,
    //       rounds: roundReqeust,
    //     },
    //     setIsLoading,
    //     handleNext
    //   )
    // );
    dispatch(
      quizState({
        key: "rounds",
        value: reduxState,
      })
    );
    handleNext();
  };
  useEffect(() => {
    if (quizRounds[currentRound - 1]) {
      quizRounds[currentRound - 1].roundId ? formik.setFieldValue("roundId", quizRounds[currentRound - 1].roundId) : formik.setFieldValue("roundId", quizRounds[currentRound - 1]._id);
      formik.setFieldValue("name", quizRounds[currentRound - 1].name);
      formik.setFieldValue(
        "description",
        quizRounds[currentRound - 1].description
      );
      formik.setFieldValue(
        "complexity",
        quizRounds[currentRound - 1].complexity
      );
      formik.setFieldValue("type", quizRounds[currentRound - 1].type);
      formik.setFieldValue(
        "passing_pr",
        quizRounds[currentRound - 1].passing_pr
      );
      formik.setFieldValue("nooftry", quizRounds[currentRound - 1].nooftry);
      formik.setFieldValue(
        "noofquestion",
        quizRounds[currentRound - 1].noofquestion
      );
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("description", "");
      formik.setFieldValue("complexity", "");
      formik.setFieldValue("type", "");
      formik.setFieldValue("passing_pr", "");
      formik.setFieldValue("nooftry", 1);
      formik.setFieldValue("noofquestion", 1);
    }
  }, [currentRound]);

  useEffect(() => {
    setRound(quizDetail.totalRounds);
  }, [quizDetail]);

  // need to uncomment this
  useEffect(() => {
    if (tempRounds.length === 0) {
      setQuizRounds(
        rounds
          .map((item) => {
            if (item.deletedAt) return;
            return {
              roundId: item._id,
              name: item.title,
              description: item.description,
              complexity: item.complexity,
              type: item.type,
              passing_pr: item.passingMarks,
              nooftry: item.noOfTry,
              noofquestion: item.totalQuestions,
            };
          })
          .filter((item) => item)
      );

      setReduxState(
        rounds
          .map((item) => {
            if (item.deletedAt) return;
            return item;
          })
          .filter((item) => item)
      );
      formik.setFieldValue("roundId", rounds[0]?._id);
      formik.setFieldValue("name", rounds[0]?.title);
      formik.setFieldValue("description", rounds[0]?.description);
      formik.setFieldValue("complexity", rounds[0]?.complexity);
      formik.setFieldValue("type", rounds[0]?.type);
      formik.setFieldValue("passing_pr", rounds[0]?.passingMarks);
      formik.setFieldValue("nooftry", rounds[0]?.noOfTry);
      formik.setFieldValue("noofquestion", rounds[0]?.totalQuestions);
      setCurrentRound(1);
    }
  }, [rounds]);

  const handlePreviousRound = () => {
    setCurrentRound(currentRound - 1);
  };

  const handleNextRound = () => {
    setCurrentRound(currentRound + 1);
  };

  useEffect(() => {
    return () => {
      tempRounds = []; // Reset the temp array to an empty array when the component unmounts
    };
  }, []);


  //   console.log("formik errors = ", formik.errors);
//   console.log("round = ", round);
  // console.log("quiz round outside = ", quizRounds);
  console.log("rounds = ", rounds);
console.log("formikk errors = ", formik.errors);

// console.log("tempRounds = ", tempRounds);
// console.log("redux state outside = ", reduxState);


  return (
    <div>
      <div className="d-flex justify-content-center fs-3 m-2"></div>
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          <div>
            <div className="d-flex justify-content-center flex-column">
              <div className="shadow p-3 mb-5 bg-body rounded">
                <Container fluid>
                  <Row>
                    <Col className="col-2">
                      {" "}
                      <p style={{ fontWeight: 500 }}>{`Round`}</p>
                    </Col>
                    <Col className="col-8"></Col>
                    <Col className="col-2">
                      <Row>
                        <div className="d-flex justify-content-between">
                          <Button
                            disabled={!quizRounds[currentRound - 2]}
                            size="sm"
                            onClick={() => {
                              handlePreviousRound();
                            }}
                            style={{
                              height: "50%",
                            }}
                          >
                            <AiOutlineCaretLeft className="custom-icon" />
                          </Button>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: "20px",
                              paddingBottom: "20px",
                            }}
                          >
                            {/* {`${currentRound}/2`} */}
                            
                            {`${currentRound}/${round}`}
                          </p>
                          <Button
                            disabled={!quizRounds[currentRound]}
                            size="sm"
                            onClick={() => {
                              handleNextRound();
                            }}
                            style={{
                              height: "50%",
                            }}
                          >
                            <AiFillCaretRight className="custom-icon" />
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-8">
                      <Form.Group
                        className="form-group mt-3"
                        controlId="formBasicName"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          autoComplete="off"
                          name={`name`}
                          value={formik.values?.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control mt-1"

                        />
                        {formik.errors.name &&  (
                          <Form.Text className="text-danger">
                            {formik.errors.name}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group
                        className="form-group mt-3"
                        controlId="formBasicNooftry"
                      >
                        <Form.Label>No. of Try</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter No Of Try"
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          min="1"
                          name={`nooftry`}
                          value={formik.values?.nooftry}
                          onChange={formik.handleChange}
                          className="form-control mt-1"
                        />
                        {console.log("formik.touched.nooftry", formik.touched.nooftry)}
                        {formik.touched.nooftry || formik.errors.nooftry ? (
                          <Form.Text className="text-danger">
                            {formik.errors.nooftry}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group
                      className="form-group mt-3"
                      controlId="formBasicDescription"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        autoComplete="off"
                        name={`description`}
                        value={formik.values?.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control mt-1"
                      />
                      {formik.errors.description &&  (
                        <Form.Text className="text-danger">
                          {formik.errors.description}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="col-6 p-2">
                      <Form.Group
                        className="form-group mt-3"
                        controlId="formBasicComplexity"
                      >
                        <Form.Label>Complexity</Form.Label>
                        <Form.Select
                          name={`complexity`}
                          value={formik.values?.complexity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="select">Select complexity</option>
                          <option value="T">T</option>
                          <option value="NE">NE</option>
                          <option value="E">E</option>
                        </Form.Select>
                        {formik.touched.complexity || formik.errors.complexity ?  (
                          <Form.Text className="text-danger">
                            {formik.errors.complexity}
                          </Form.Text>
                        ): null}
                      </Form.Group>
                    </Col>
                    <Col className="col-6 p-2">
                      <Form.Group
                        className="form-group mt-3"
                        controlId="formBasicType"
                      >
                        <Form.Label>Type</Form.Label>
                        <Form.Select
                          value={formik.values?.type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name={`type`}
                        >
                          <option value="ANY">Select type</option>
                          <option value="VIS">VIS</option>
                          <option value="TXT">TXT</option>
                        </Form.Select>
                        {formik.touched.type || formik.errors.type ?  (
                          <Form.Text className="text-danger">
                            {formik.errors.type}
                          </Form.Text>
                        ): null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6 p-2">
                      <Form.Group className="form-group mt-3">
                        <Form.Label>Passing percentage (%)</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter PR"
                          autoComplete="off"
                          name={`passing_pr`}
                          value={formik.values?.passing_pr}
                          min={1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control mt-1"
                        />
                        {formik.errors.passing_pr &&  (
                          <Form.Text className="text-danger">
                            {formik.errors.passing_pr}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-6">
                      <Form.Group
                        className="form-group mt-3"
                        controlId="formBasicNoofquestion"
                      >
                        <Form.Label>No. of Questions</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter No Of Questions"
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          min="1"
                          name={`noofquestion`}
                          value={formik.values?.noofquestion}
                          onChange={formik.handleChange}
                          className="form-control mt-1"
                        />
                        {formik.errors.noofquestion && (
                            <Form.Text className="text-danger">
                              {formik.errors.noofquestion}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ span: 4, offset: 8 }}
                      className="d-flex justify-content-end"
                    >
                      <Button
                        variant=""
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="btn btn-success ms-2"
                      >
                        {formik.isSubmitting ? (
                          <Fragment>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />{" "}
                            Please wait...
                          </Fragment>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Form>
      </Container>
      <div className="d-flex m-4 justify-content-end">
        <Button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            handlePrevious();
          }}
        >
          Previous
        </Button>
        <Button
          variant="primary"
          type="button"
          className="ms-2"
          disabled={quizRounds.length !== round}
          onClick={handleNextButton}
        >
          {isLoading ? (
            <Fragment>
              <Spinner animation="border" size="sm" className="me-2" /> Please
              wait...
            </Fragment>
          ) : (
            "Next"
          )}
        </Button>
      </div>
    </div>
  );
};

export default QuizRoundNew;
