import React, { useEffect } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

const DisplayQueImage = ({queData}) => {

	const isGoogleDriveImage = (url) => {
		return url.includes('drive.google.com');
	};
	
	const getGoogleDriveImageSrc = (url) => {
		const fileId = url.split('/d/')[1].split('/')[0];
		return `https://drive.google.com/thumbnail?id=${fileId}`;

		// return `https://drive.google.com/uc?export=view&id=${fileId}`;
	};


	return queData?.type === 'VIS' && queData?.url !== '' ?
						 
	<div className="row">
		<div className="col-md-12 p-2">
			{isGoogleDriveImage(queData?.url) ?  <img src={getGoogleDriveImageSrc(queData?.url)} alt={queData?.correctAnswer} style={{height: "200px"}} /> :  <img src={queData?.url} alt={queData?.correctAnswer} style={{height: "200px"}} />}
		</div>
	</div>

: null
}

const ViewQuestion = ({ show, modalClose, data = "" }) => {

	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>View Question</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<div className="row">
						<div className="form-group">
							<div className="form-label">Question</div>
							<div type="text" className="form-control mt-1">
								{data?.question}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 p-2">
							<div className="form-group mt-3">
								<div className="form-label">Complexity</div>
								<div type="text" className="form-control mt-1">
									{data?.complexity}
								</div>
							</div>
						</div>
						<div className="col-md-4 p-2">
							<div className="form-group mt-3">
								<div className="form-label">Type</div>
								<div type="text" className="form-control mt-1">
									{data?.type}
								</div>
							</div>
						</div>
						<div className="col-md-4 p-2">
							<div className="form-group mt-3">
								<div className="form-label">Time (in Sec)</div>
								<div type="text" className="form-control mt-1">
									{data?.time}
								</div>
							</div>
						</div>
					</div>
					<DisplayQueImage 
						queData={data}
					/>
					<Row className="mt-2">
						{data?.options?.map((option, index) => {
							return (
								<Col className="col-6 p-2" key={index}>
									<Form.Group>
										<Form.Label>
											Option {index + 1}
										</Form.Label>
										<InputGroup className="mb-3">
											<InputGroup.Checkbox
												aria-label="Checkbox for following text input"
												checked={
													data?.correctAnswer ===
													option
												}
												disabled
											/>
											<Form.Control
												name="option1"
												value={option}
												aria-label="Text input with checkbox"
											/>
										</InputGroup>
									</Form.Group>
								</Col>
							);
						})}
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer className="mt-3">
				<Button
					variant="secondary"
					onClick={() => {
						modalClose();
					}}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ViewQuestion;
