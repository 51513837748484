import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";
import { createQuizDetail, personaList, sponserList } from "../../store/quiz/quiz.fetch";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { FaTimesCircle } from "react-icons/fa";
import { uploadImageToAzure } from "../../Utils/ImageUpload";
import { getStatus } from "../../Utils/GetQuizStatus"

const QuizDetail = ({ handleNext, mode }) => {
	const validationSchema = Yup.object().shape({
		title: Yup.string().min(5, "Too Short!").max(30, "You are allow to add only 30 character's long title").required("Title is required"),
		rounds: Yup.number("accept in number only").min(1, "Minimum one round is required").required("Number of round is required"),
		start_date: Yup.date().required("Start date is required"),
		end_date: Yup.date().min(Yup.ref("start_date"),"End date must be greater than or equal to start date"),
		practiceQuiz: Yup.boolean(),
		selectedPersona: Yup.array()
			.when("practiceQuiz", {
				is:  false,
				then: (schema) => schema.required("Contest selection is required").min(1, "You need to select at least one contest"),
				otherwise: (schema) => schema,
			}),
	});

	const customStyles = {
		option: (defaultStyles, state) => ({
			...defaultStyles,
			color: state.isSelected ? "#343a40" : "#fff",
			backgroundColor: state.isSelected ? "#a0a0a0" : "#343a40",
		}),

		control: (defaultStyles, state) => ({
			...defaultStyles,
			backgroundColor: "#343a40",
			border: "none",
			boxShadow: "none",
			borderColor: state.isFocused ? "#8d65db" : "gray",
			"&:hover": {
				border: "gray",
			},
		}),
		singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "white",
			};
		},
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			backgroundColor: "#FFBDAD",
			color: "red",
		}),
	};

	const [initialValues, setInitialValues] = useState({
		quiz: "",
		title: "",
		rounds: "",
		start_date: "",
		end_date: "",
		practiceQuiz: false,
		selectedPersona: [],
		sponser: null,
		image: "",
	});
	const [personas, setPersonas] = useState([]);
	const [sponsers, setSponsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(mode === 'create' ? false : true);

	const [isActive, setIsActive] = useState(false);
	const [imageError, setImageError] = useState("");

	const dispatch = useDispatch();
	const quiz = useSelector((state) => state.quiz.quizDetail);
	
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			
			let startDate = moment(value.start_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
			let endDate = value.end_date;

			if(endDate) {
				endDate = moment(value.end_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
			}

			// return
			setSubmitting(true);
			if(!value.sponser) delete value.sponser
			if(typeof value.sponser === 'object') value.sponser = value.sponser.value
			const apiRequest = {
				id: value.quiz,
				title: value.title,
				practiceQuiz: value.practiceQuiz,
				startDate: startDate,
				endDate: endDate,
				totalRounds: value.rounds,
				persona: value.selectedPersona?.map((item) => item.value),
				sponsor: value?.sponser,
				image: value.image,
			};
			await dispatch(
				createQuizDetail(apiRequest, setSubmitting, handleNext)
			);
		},
	});

	const handlePersona = (selectedOptions) => {
		try {
			formik.setFieldValue("selectedPersona", selectedOptions);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleImageUpload = async (e) => {
		setIsLoading(true);
		setImageError("");
		const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

		if(supportedFormats.includes(e.target.files[0].type)) { 
			const url = await uploadImageToAzure(e.target.files[0]);
			formik.setFieldValue("image", url);
			setIsLoading(false);
		}else {
			formik.setFieldValue("image", "");
			setImageError("Only image files are allowed");
			setIsLoading(false);
		}

	};

	useEffect(() => {
		personaList().then((response) => {
			setPersonas(
				response.map((item) => {
					return {
						id: item._id,
						label: item.name,
						value: item._id,
					};
				})
			);
		});
		sponserList().then((response) => {
			setSponsers(
				response.map((item) => {
					return {
						key: item.name,
						value: item._id,
					};
				})
			);
		});
	}, []);

	useEffect(() => {
		if (Object.keys(quiz).length !== 0 && personas?.length && sponsers?.length) {
			const _isActive = quiz.status === 'draft' ? false : getStatus(quiz.startDate, quiz.endDate) === 'Active' || getStatus(quiz.startDate, quiz.endDate) === 'Finished' ? true : false
			setIsActive(_isActive)
			const _sponser = typeof quiz?.sponsor === 'string' ?  sponsers?.find(({value}) => value === quiz?.sponsor) : {
				key: quiz?.sponsor?.name,
				value: quiz?.sponsor?._id,
			}
			formik.setValues({
				quiz: quiz._id,
				title: quiz.title,
				rounds: quiz.totalRounds,
				start_date: moment(quiz.startDate).format("YYYY-MM-DD"),
				end_date: quiz.endDate
					? moment(quiz.endDate).format("YYYY-MM-DD")
					: "",
				sponser: _sponser,
				practiceQuiz: quiz.practiceQuiz,
				selectedPersona: quiz.persona?.map((item) => {
					const find = typeof item === 'string' ? personas?.find(({id}) => id === item) : personas?.find(({id}) => id === item?._id)
					return { id: find?.id, label: find?.label, value: find?.id }
				}),
				image: quiz.image,
			});
			setIsDataLoading(false)
		}
	}, [quiz, personas?.length, sponsers?.length]);

	return (
		<div>
			<Form onSubmit={formik.handleSubmit} style={{ overflowY: 'auto', height: '500px' }}>
				<div className="d-flex justify-content-center fs-3">
					Quiz Detail
				</div>
				<div className="d-flex justify-content-center flex-column">
					<Container fluid>

					{isDataLoading ? 
							<div className="d-flex justify-content-center" style={{marginTop: "130px"}}>
								<div className="page_loader" />
							</div>
							: <>
						<Row>
							<Col className="col-6">
								<Form.Group
								className="form-group mt-3"
								controlId="formBasicName"
								>
									<Form.Label>Title</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter title"
										autoComplete="off"
										name="title"
										readOnly={isActive}
										value={formik.values.title}
										onChange={formik.handleChange}
										className="form-control mt-1"
									/>
									{formik.errors.title && formik.touched.title ? (
										<Form.Text className="text-danger">
											{formik.errors.title}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6 ">
								<Form.Group
									controlId="formLogoImage"
									className="form-group"
									style={{ marginTop: "48px" }}
								>
									<Form.Check
										type="checkbox"
										name="practiceQuiz"
										label="Practice Quiz ?"
										className="custom-date-input"
										disabled={isActive}
										checked={formik.values.practiceQuiz}
										value={formik.values.practiceQuiz}
										onChange={formik.handleChange}
									/> 
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3 m-auto"
									controlId="formBasicPersona"
								>
									<Form.Label className="pt-2">
										Contest's
									</Form.Label>
									<Select
										defaultValue={formik.values.selectedPersona}
										options={personas}
										isMulti
										readOnly={isActive}
										isDisabled={formik.values.practiceQuiz || isActive}
										onChange={handlePersona}
										value={formik.values.selectedPersona ? formik.values.selectedPersona : []}
										styles={customStyles}
									/>
								</Form.Group>
								{formik.errors.selectedPersona &&
								formik.touched.selectedPersona ? (
									<Form.Text className="text-danger">
										{formik.errors.selectedPersona}
									</Form.Text>
								) : null}
							</Col>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label className="pt-2">
										Sponser
									</Form.Label>
									<Form.Select
										className=""
										name="sponser"
										disabled={isActive}
										style={{ overflowY: "scroll" }}
										onChange={formik.handleChange}
										value={formik.values?.sponser?.value}
										onBlur={formik.handleBlur}
									>
										<option value="">Please select</option>
										{sponsers.map((item, index) => (
											<option
												value={item.value}
												key={index}
											>
												{item.key}
											</option>
										))}
									</Form.Select>
									{formik.errors.sponser &&
										formik.touched.sponser && (
											<Form.Text className="text-danger">
												{formik.errors.sponser}
											</Form.Text>
										)}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicRound"
								>
									<Form.Label>Total Round</Form.Label>
									<Form.Control
										type="number"
										min="1"
										placeholder="Enter total round"
										autoComplete="off"
										name="rounds"
										readOnly={isActive}
										value={formik.values.rounds}
										onChange={formik.handleChange}
										className="form-control mt-1"
									/>
									{formik.errors.rounds &&
									formik.touched.rounds ? (
										<Form.Text className="text-danger">
											{formik.errors.rounds}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6">
								<Form.Group
									controlId="formLogoImage"
									className="form-group mt-3"
								>
									<Form.Label>Image</Form.Label>
									<Form.Control
										type="file"
										readOnly={isActive}
										accept="image/*"
										onChange={(e) => {
											handleImageUpload(e);
										}}
									/>
									{imageError && (
										<Form.Text className="text-danger validation-message">
											{imageError}
										</Form.Text>
									)}

									{formik.values.image && (
										<div
											className="position-relative mt-3"
											style={{
												width: "150px",
												height: "auto",
											}}
										>
											<Image
												src={formik.values.image}
												fluid
												alt="Preview"
												sizes="sm"
											/>
											<FaTimesCircle
												className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
												// style={{
												// 	top: "-7px",
												// 	right: "9px"
												// }}
												onClick={() => {
													formik.setFieldValue("image","");
												}}
											/>
										</div>
									)}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label>Start Date</Form.Label>
									<Form.Control
										type="date"
										name="start_date"
										min={mode === 'create' ? new Date().toISOString().split('T')[0] : null}
										placeholder="start Date"
										readOnly={isActive}
										className="custom-date-input"
										value={formik.values.start_date}
										onChange={formik.handleChange}
									/>
									{formik.errors.start_date &&
									formik.touched.start_date ? (
										<Form.Text className="text-danger">
											{formik.errors.start_date}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label>End Date</Form.Label>
									<Form.Control
										type="date"
										name="end_date"
										placeholder="end Date"
										className="custom-date-input"
										readOnly={formik.values.practiceQuiz || isActive}
										value={formik.values.end_date}
										min={formik.values.start_date ? new Date(formik.values.start_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
										onChange={formik.handleChange}
									/>
									{formik.errors.end_date &&
									formik.touched.end_date ? (
										<Form.Text className="text-danger">
											{formik.errors.end_date}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							
						</Row>
						</>
}
					</Container>
				</div>
				<div className="d-flex m-4 justify-content-end">
					<Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						{formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Next"
						)}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default QuizDetail;
