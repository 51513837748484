import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaEdit, FaEye, FaUser } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useDebounce } from "../../hooks/useDebounce";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/common/BreadCrumb";
import CommonTable from "../../components/common/Table";
import moment from "moment";
import { useDispatch } from "react-redux";
import { quizState } from "../../store/quiz/quiz.slice";
import { personaList } from "../../store/quiz/quiz.fetch";
import Multiselect from "multiselect-react-dropdown";
import { getStatusWithHtml, getStatus } from "../../Utils/GetQuizStatus";
import { BsChevronDown } from "react-icons/bs";
import FinishedQuiz from "../../components/quiz/FinishedQuiz";

const Quiz = () => {
	const [quizzes, setQuizzes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [dataTotalCount, setDataTotalCount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const [personas, setPersonas] = useState([]);
	const [contestName, setContestName] = useState("");
	const fetchIdRef = useRef(0);
	const debounceSearch = useDebounce(searchText, 500);
	const toggleRef = React.useRef(false);
	const [pageData, setPageData] = useState({
		pageSize: 10,
		pageIndex: 0,
		sortBy: [],
		filter: "ALL"
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showFinishedQuizModel, setShowFinishedQuizModel] = useState(false);
	const [finishedQuizData, setFinishedQuizData] = useState({});

	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				Cell: ({ row }) => {
					return row.index + 1;
				},
			},
			{
				Header: "Quiz ID",
				Cell: ({ row }) => {
					console.log({row})
					return row.original._id;
				},
			},
			{
				sorting: true,
				Header: "Name",
				accessor: "title",
				Cell: ({ row }) => {
					return row?.original?.title ? row?.original?.title : '-' 
				  }
			},
			{
				sorting: true,
				Header: "Total Round",
				accessor: "totalRounds",
				Cell: ({ row }) => {
					return row?.original?.totalRounds ? row?.original?.totalRounds : '-' 
				  }
			},
			{
				sorting: true,
				Header: "Contest",
				accessor: "persona",
				Cell: ({ row }) => {
					if (row?.original?.persona) {
						const name = row?.original?.persona?.map((persona) => {
							return persona?.name;
						});
						return name.length ? name.join(", ") : "-";
					}
					return "-";
				},
			},
			{
				sorting: true,
				Header: "Sponser",
				accessor: "sponsor.name",
				Cell: ({ row }) => {
					return row?.original?.sponsor?.name ? row?.original?.sponsor?.name : '-' 
				  }
				
			},
			{
				sorting: false,
				Header: "Start Time",
				Cell: ({ row }) => {
					return row?.original?.startDate
						? moment.utc(row?.original?.startDate).local().format("Do MMMM YYYY")
						: "-";
				},
			},
			{
				sorting: false,
				Header: "End Time",
				Cell: ({ row }) => {
					return row?.original?.endDate
						? moment.utc(row?.original?.endDate).local().format("Do MMMM YYYY")
						: "-";
				},
			},
			{
				Header: "Status",
				Cell: ({ row }) => {
					return row?.original?.status === "draft" ? (
						<span className="badge bg-warning">Draft</span>
					) : getStatusWithHtml(row?.original?.startDate, row?.original?.endDate);
				},
			},
			{
				Header: "Action",
				Cell: ({ row }) => {
					const isHide = row?.original?.status === 'draft' ? false : getStatus(row?.original?.startDate, row?.original?.endDate) === 'Active' ? true : false
					const isFinished = getStatus(row?.original?.startDate, row?.original?.endDate) === 'Finished' ? true : false;
					return (
						<div className="d-flex justify-start">
							<FaEye
								className=""
								style={{ fontSize: "20px", color: "#8a8d93", cursor: "pointer" }}
								onClick={() => {
									navigate(`/quiz/${row?.original?._id}`);
								}}
							/>
							
							{!isHide ? 
								isFinished && row?.original?.status !== "draft" ? 
								<FaEdit
									style={{
										fontSize: "20px",
										color: "#7385C9",
										marginLeft: "11px",
										cursor: "pointer"
									}}
									onClick={() => {
										handleFinishedQuiz(row);
									}}
								/> : 
								
								<FaEdit
									style={{
										fontSize: "20px",
										color: "#7385C9",
										marginLeft: "11px",
										cursor: "pointer"
									}}
									onClick={() => {
										navigate(
											`/quiz/edit/${row?.original?._id}`
										);
									}}
							/> : null}

							<MdDelete
								style={{
									fontSize: "20px",
									color: "#BD5357",
									marginLeft: "11px",
									cursor: "pointer"
								}}
								onClick={() => {
									handleDeleteQuiz(row?.original);
								}}
							/>
						</div>
					);
				},
			},
		],
		[]
	);

	const fetchData = useCallback(
		async ({ pageSize, pageIndex, sortBy, filter = pageData.filter }) => {
			const fetchId = ++fetchIdRef.current;
			const sortColumn = sortBy[0]?.id;
			const sortOrder = sortBy[0]?.desc ? "desc" : "asc";
			const _contestName = contestName; 

			setLoading(true);
			if (searchText) {
				pageIndex = 0;
			}
			setPageData({
				pageSize: pageSize,
				pageIndex: pageIndex,
				sortBy: sortBy,
				filter
			});
			if (fetchId === fetchIdRef.current) {
				const res = await AxiosRequest(`/admin/quiz?limit=${pageSize}&page=${pageIndex + 1}&search=${searchText}&sortBy=${sortColumn}&sortAt=${sortOrder}&filter=${filter}`);
				setQuizzes(res?.data?.data?.data || []);
				setPageCount(Math.ceil(res?.data?.data.totalCount / pageSize));
				setDataTotalCount(res?.data?.data.totalCount)
				setLoading(false);
			}
		},
		[debounceSearch, searchText, contestName]
	);

	const handleFinishedQuiz = (data) => {
		try {
			setShowFinishedQuizModel(true);
			setFinishedQuizData(data.original);
		} catch (err) {
			console.log("err", err);
		}
	}

	const handleDeleteQuiz = (data) => {
		try {
			Swal.fire({
				icon: "error",
				title: "Are you sure?",
				text: "You want to delete this quiz?",
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: "#dc3545",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (result.isConfirmed) {
						const response = await AxiosRequest.delete(
							`/admin/quiz/${data._id}`
						);
						fetchData(pageData);
						if (response.data.status) {
							Swal.fire({
								icon: "success",
								title: "Quiz has been deleted successfully!",
								confirmButtonColor: "#8d65db",
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error in deleting quiz. Please try again!",
								confirmButtonColor: "#8d65db",
							});
						}
					}
				}
			});
		} catch (err) {
			console.log("err", err);
		}
	};

	useEffect(() => {
		personaList().then((response) => {
			setPersonas([
				{
					_id: "",
					name: "ALL"
				},
				...response.map((item) => {
					return {
						_id: item._id,
						name: item.name,
					};
				})
			]);
		});
	}, []);

	const onSelectContest = (selectedList, selectedItem) => {
		setContestName(selectedItem?.name)
		fetchData({...pageData, filter:selectedItem?.name })
	}
	
	const onRemoveContest = (selectedList, selectedItem) => {
		setContestName(selectedItem?.name)
		fetchData({...pageData, filter:"ALL" })	
	}

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">Quiz</h3>
					<button
						className="btn btn-primary ms-3"
						onClick={() => {
							dispatch(
								quizState([
								  { key: "quizDetail", value: {} },
								  { key: "rounds", value: [] },
								  { key: "questions", value: [] },
								])
							  );
							navigate("/quiz/create");
						}}
					>
						Add Quiz
					</button>
				</div>
			</div>
			<BreadCrumb page="Quiz" />

			<FinishedQuiz
				modalClose={() => {
					setShowFinishedQuizModel(false);
				}}
				show={showFinishedQuizModel}
				data={finishedQuizData}
				pageData={pageData}
				fetchData={fetchData}
			/>

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row flex align-items-end">
						<div className="col-md-4">
							<label className="form-label pt-2">
								Search
							</label>
							<input
								type="text"
								placeholder="Search"
								className="form-control "
								aria-label="Search"
								onChange={(e) => {
									setSearchText(e.target.value);
									toggleRef.current = true;
								}}
							/>
						</div>
						<div className="col-md-4">
							<label className="form-label pt-2">
								Select Contest
							</label>
							<Multiselect
								options={personas}
								onSelect={onSelectContest}
								onRemove={onRemoveContest}
								displayValue="name"
								showCheckbox={true}
								placeholder="Select Contest"
								emptyRecordMsg="No Contest"
								selectionLimit="1"
								singleSelect={true}
								// showArrow={false}
								customArrow={<BsChevronDown style={{color: "#fff"}}/>}
								style={{
									chips: {
									  background: '#9273d1',
									  color: 'white'
									},
									multiselectContainer: {
									  color: '#eef3ff',
									  background: '#2d3035',
									  border: '#8a8d93'
									},
									optionContainer: { 
										background: '#2d3035'
									},
									searchBox: {
										background: '#2d3035',
										border: '1px solid #40444b',
										padding: "4px"
									},
									inputField: {
										color: '#8a8d93'
									},
								}}
							/>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
					</div>
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							<div className="card mb-0">
								<div className="card-header"></div>
								<div className="card-body pt-0">
									<div className="table-responsive">
										<CommonTable
											columns={columns}
											data={quizzes}
											fetchData={fetchData}
											loading={loading}
											pageCount={pageCount}
											manualPagination={true}
											manualSort={true}
											// defaultSortColumn={"title"}
											toggleRef={toggleRef}
											dataTotalCount={dataTotalCount}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Quiz;
